'use strict';
jQuery(document).ready(function($) {

   const deviceAgent = navigator.userAgent.toLowerCase();
   const agentID = deviceAgent.match(/(iphone|ipod|ipad|android|webos|iemobile|opera mini|blackberry)/);

   if (agentID) {
      $('html').addClass('is-mobile');
   }

   $('[data-svg]').each(function(index, el) {
      const $img = $(el);
      const imgID = $img.attr('id');
      const imgClass = $img.attr('class');
      const imgURL = $img.data('svg');
      const imgWidth = $img.attr('width');

      $.get(imgURL, function(data) {
         // Get the SVG tag, ignore the rest
         let $svg = $(data).find('svg');

         // Add replaced image's ID to the new SVG
         if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
         }
         // Add replaced image's classes to the new SVG
         if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
         }

         if (typeof imgWidth !== 'undefined') {
            $svg = $svg.attr('width', imgWidth);
         }

         // Remove any invalid XML tags as per http://validator.w3.org
         $svg = $svg.removeAttr('xmlns:a');

         // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
         if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
         }

         // Replace image with new SVG
         $img.replaceWith($svg);

      }, 'xml');
   });


   $('body').on('click', '.jumper, .jumper-to > a', function(e) {
      e.preventDefault();
      const anchor = $(this).attr('href');
      $('html,body').animate({ scrollTop: $(anchor).offset().top }, 1000);
   });

   $(document).on('click', '.icon-table-folder-plus', function(event){
      event.preventDefault();
      // $('.table-row').removeClass('is-active');
      $(this).parents().eq(1).toggleClass('is-active');
   });

   // // Slide default
   // $('.slide-hero').each(function(index, el) {
   //    const $slide = $(el);
   //    const prev = $slide.data('prev');
   //    const next = $slide.data('next');
   //    const pagination = $slide.data('pagination'); 
      
   //    const slideHero = new Swiper(el, {
   //       spaceBetween: 0,
   //       autoplay: {
   //          delay: 8000,
   //       },
   //       loop: true,
   //       speed: 1000,
   //       parallax: true,
   //       navigation: {
   //          nextEl: next,
   //          prevEl: prev,
   //       },
   //       pagination: {
   //          el: pagination,
   //          clickable: true,
   //       }
   //    });
   // });


   // // Validação de formulário
   // $('.form-contato').validate({
   //    errorPlacement: function(error, element) {
   //       return false;
   //    },
   //    rules: {
   //       nome: {
   //          required: true,
   //          minlength: 2
   //       },
   //       email: {
   //          required: true,
   //          email: true,
   //       },
   //       telefone: {
   //          required: true,
   //          minlength: 2
   //       },
   //    },
   //    submitHandler: function(form) {
   //       // const formData = $(form).serialize();
   //    }
   // });

   /* JQUERY MASK */
   if(jQuery().mask){
      // Formatando campos com mascaras
      const SPMaskBehavior = function(val) {
         return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
      },
      spOptions = {
         onKeyPress: function(val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
         }
      },
      optionsCpfCnpj = {onKeyPress: function(documento, e, field, optionsCpfCnpj){
         var masks = ['000.000.000-009', '00.000.000/0000-00'];
         var mask = (documento.length>14) ? masks[1] : masks[0];
         $('.mask-document').mask(mask, optionsCpfCnpj);
      }};

      $('.mask-phone').mask(SPMaskBehavior, spOptions);
      $('.mask-date').mask('99/99/9999');
      $('.mask-cep').mask('00000-000');
      $('.mask-cpf').mask('000.000.000-00');
      $('.mask-cnpj').mask('00.000.000/0000-00');
      $('.mask-number').mask('0#');
      $('.mask-creditcard').mask('9999 9999 9999 9999');
      $('.mask-document').mask('000.000.000-00',optionsCpfCnpj);
      $('.mask-money').mask('#.##0,00', {reverse: true});
   }


   /* JQUERY VALIDATE */
	if(jQuery().validate){

      $.validator.addMethod('fullname', function(str) {
         var match = str.match(/\b[A-Za-z]{2,}\b/g);
         return match.length > 1;
      }, 'Informe seu nome completo');
 
      $.validator.addMethod( "cpfBR", function( value ) {
         // Removing special characters from value
         value = value.replace( /([~!@#$%^&*()_+=`{}\[\]\-|\\:;'<>,.\/? ])+/g, "" );
 
         // Checking value to have 11 digits only
         if ( value.length !== 11 ) {
            return false;
         }
 
         var sum = 0, firstCN, secondCN, checkResult, i;
 
         firstCN = parseInt( value.substring( 9, 10 ), 10 );
         secondCN = parseInt( value.substring( 10, 11 ), 10 );
 
         checkResult = function( sum, cn ) {
            var result = ( sum * 10 ) % 11;
            if ( ( result === 10 ) || ( result === 11 ) ) {
               result = 0;
            }
            return ( result === cn );
         };
 
         // Checking for dump data
         if ( value === "" ||
             value === "00000000000" ||
             value === "11111111111" ||
             value === "22222222222" ||
             value === "33333333333" ||
             value === "44444444444" ||
             value === "55555555555" ||
             value === "66666666666" ||
             value === "77777777777" ||
             value === "88888888888" ||
             value === "99999999999"
         ) {
            return false;
         }
 
         // Step 1 - using first Check Number:
         for ( i = 1; i <= 9; i++ ) {
            sum = sum + parseInt( value.substring( i - 1, i ), 10 ) * ( 11 - i );
         }
 
         // If first Check Number (CN) is valid, move to Step 2 - using second Check Number:
         if ( checkResult( sum, firstCN ) ) {
            sum = 0;
            for ( i = 1; i <= 10; i++ ) {
               sum = sum + parseInt( value.substring( i - 1, i ), 10 ) * ( 12 - i );
            }
            return checkResult( sum, secondCN );
         }
         return false;
 
      }, "Please specify a valid CPF number" );
 
      $.validator.addMethod('cnpj', function(cnpj) {
         cnpj = jQuery.trim(cnpj);
 
         // DEIXA APENAS OS NÚMEROS
         cnpj = cnpj.replace('/','');
         cnpj = cnpj.replace('.','');
         cnpj = cnpj.replace('.','');
         cnpj = cnpj.replace('-','');
 
         var numeros, digitos, soma, i, resultado, pos, tamanho, digitos_iguais;
         if (cnpj.length == 0) {
            return false;
         }
 
         cnpj = cnpj.replace(/\D+/g, '');
         digitos_iguais = 1;
 
         for (i = 0; i < cnpj.length - 1; i++)
            if (cnpj.charAt(i) != cnpj.charAt(i + 1)) {
               digitos_iguais = 0;
               break;
            }
         if (digitos_iguais)
            return false;
 
         tamanho = cnpj.length - 2;
         numeros = cnpj.substring(0,tamanho);
         digitos = cnpj.substring(tamanho);
         soma = 0;
         pos = tamanho - 7;
         for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
               pos = 9;
         }
         resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
         if (resultado != digitos.charAt(0)){
            return false;
         }
         tamanho = tamanho + 1;
         numeros = cnpj.substring(0,tamanho);
         soma = 0;
         pos = tamanho - 7;
         for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
               pos = 9;
         }
 
         resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
 
         return (resultado == digitos.charAt(1));
 
      }, 'Informe um CNPJ válido.');
 
      $.validator.addMethod("documento", function(value, element) {
         // remove pontuações
         value = value.replace('.','');
         value = value.replace('.','');
         value = value.replace('-','');
         value = value.replace('/','');
 
         if (value.length <= 11) {
         if(jQuery.validator.methods.cpfBR.call(this, value, element)){
            return true;
         } else {
            this.settings.messages.documento = "Informe um CPF válido.";
         }
 
         }
         else if (value.length <= 14) {
           if(jQuery.validator.methods.cnpj.call(this, value, element)){
              return true;
           } else {
              this.settings.messages.documento = "Informe um CNPJ válido.";
           }
         }
         return false;
 
      }, "Informe um documento válido.");
 
      $.validator.addMethod("phoneBR", function(value, element) {
         return this.optional( element ) || /(\({0,1}\d{0,2}\){0,1} {0,1})(\d{4,5}) {0,1}-{0,1}(\d{4})/.test(value);
      }, "Informe um número válido");
 
      $.validator.methods.email = function( value, element ) {
         return this.optional( element ) || /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test( value );
      };
 
      // create a custom phone number rule called 'intlTelNumber'
      // $.validator.addMethod("intlTelNumber", function(value, element) {
      //     return this.optional(element) || $(element).intlTelInput("isValidNumber");
      // }, "Insira um número válido");
 
      $.validator.messages.required = 'Preencha este campo';
      $.validator.messages.email = 'Digite um e-mail válido';
      $.validator.messages.cpfBR = 'Digite um CPF válido';
      $.validator.messages.fullname = 'Informe seu nome completo';
 
      $.validator.setDefaults({
         'errorElement':'span',
         'focusInvalid': true,
         // 'onsubmit': false,  // Removido, estava quebrando o envio do formulário
         'rules':{
            nome_completo: {fullname:true},
            email: {email: true},
            celular: {phoneBR:true},
            documento: {documento: true},
            cnpj: {cnpj: true},
            cpf: {cpfBR:true}
         },
         'messages': {
            telefone: {required:'Informe um número válido'},
            celular: {required:'Informe um número válido'},
            nome: {required:'Digite seu nome'},
            nome_completo: {required:'Digite seu nome completo'},
            email: {required:'Digite um e-mail válido'},
            cnpj: {required:'Digite um CNPJ válido'},
            cpf: {required:'Digite um CPF válido'},
            documento: {required:'Digite um documento válido'},
            perfil: {required:'Escolha um perfil'},
         extra: {required:'Escolha uma categoria'},
            mensagem: {required:'Preencha este campo com sua mensagem'}
         }
      });
   }

   // LightBox
   $(".open-fancybox").fancybox({
      // 'titleShow'     : false
   });

   // SmoothScroll({
   //    animationTime: 600,
   //    stepSize: 50,
   //    // accelerationDelta: 100,
   // });
   
   $('[data-change-display]').on('change', function(){
      const el = $(this).data('change-display');
      $(el).fadeIn();
   });

   $('.input-toggle-password__icon').on('click', function(){
      const $icon = $(this);
      const $input = $icon.parent().find('input');

      $icon.toggleClass('is-open');
      
      if($input.attr('type') == 'password'){
         $input.attr('type', 'text');
      }else{
         $input.attr('type', 'password');
      }
   });
});


const rangeInputs = document.querySelectorAll('input[type="range"]')
// const numberInput = document.querySelector('input[type="number"]')
let isRTL = document.documentElement.dir === 'rtl'

function handleInputChange(e) {
   let target = e.target
   if (e.target.type !== 'range') {
      target = document.getElementById('range')
   } 
   const min = target.min
   const max = target.max
   const val = target.value
   let percentage = (val - min) * 100 / (max - min)
   if (isRTL) {
      percentage = (max - val) 
   }
  
   target.style.backgroundSize = percentage + '% 100%'
}

rangeInputs.forEach(input => {
   input.addEventListener('input', handleInputChange)
})

// numberInput.addEventListener('input', handleInputChange)

// Handle element change, check for dir attribute value change
function callback(mutationList, observer) {  
   mutationList.forEach(function(mutation) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'dir') {
         isRTL = mutation.target.dir === 'rtl'
      }
   })
}

// Listen for body element change
const observer = new MutationObserver(callback)
observer.observe(document.documentElement, {attributes: true})

const locales = {
   days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
   daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
   daysMin: ['Seg', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
   months: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho', 'Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
   monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
   today: 'Hoje',
   clear: 'Limpar',
   dateFormat: 'dd/mm/yyyy',
   timeFormat: 'hh:ii aa',
   firstDay: 0
};

new AirDatepicker('.input-calendar', {
   // inline: true,
   view: 'months',
   minView: 'months',
   dateFormat: 'MMMM yyyy',
   maxDate: new Date(),
   onHide(){
      console.log('fechei');
      $('.form-calendar').submit();
   },
   locale: locales
});

function checkFileType(type){
   let fileType = type.split('/');
   let fileExtension = fileType.length > 1 ? fileType[1] : fileType[0];
   let fileExtensions = ['jpg','jpeg','gif','png'];

   if( fileExtensions.includes(fileExtension)) {
      return true
   }

   return false;
}

function displayErrorUploadImageProfile(element, message){
   const photoPreview = element.parent().find('.form-upload-photo__image img');
   const photoError = element.parent().find('.form-upload-photo__error');
   photoPreview.attr('src', '');
}

$(".form-upload-photo__input").change(function(){
   const input = $(this); 
   const photoPreview = input.parent().find('.form-upload-photo__image img');
   if (this.files && this.files[0]) {
      const reader = new FileReader();
      const file = this.files[0];

      // Verifica se a imagem de mais de 1MB
      if(file.size >= 1048576){
         displayErrorUploadImageProfile(input, 'A imagem nÃ£o pode ser maior que 1MB');
         return;
      }

      if(!checkFileType(file.type)){
         displayErrorUploadImageProfile(input, 'Tipo de arquivo nÃ£o permitido! <br>Envie a foto nos formatos: jpg, png ou gif');
         return;
      }

      reader.onload = function (e) {
         photoPreview.attr('src', e.target.result);
      }

      reader.readAsDataURL(file);
   }else{
      photoPreview.removeAttr('src');
   }
});